import {colors} from "@c10h/colors";
import CovidTestModalItem from "@components/CovidTestModalItem";
import {FeatureFlag} from "@services/featureFlagConstants";
import {ApptSlot} from "@services/monolith/availability";
// @ts-expect-error TS7016: Could not find a declaration file for module 'color'. '/Users/goksel/Sites/carbon-website-next/node_modules/color/index.js' implicitly has an 'any' type.
import Color from "color";
import {DefaultTFuncReturn} from "i18next";
import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React, {useCallback, useEffect, useMemo, useState} from "react";
// @ts-expect-error TS7016: Could not find a declaration file for module 'react-outside-click-handler'. '/Users/goksel/Sites/carbon-website-next/node_modules/react-outside-click-handler/index.js' implicitly has an 'any' type.
import OutsideClickHandler from "react-outside-click-handler";
import {useDispatch} from "react-redux";
import {actions, useTypedSelector} from "src/store";
import {selectSelectedRegion} from "src/store/slices/userLocation";
import {v4} from "uuid";

import {ApptReasonId} from "../../constants/apptReasons";
import {SpecialtyId} from "../../constants/specialtyIds";
import {useResultExtensions} from "../../hooks/useCareEntryExtensions";
import {useFeatureFlag} from "../../hooks/useFeatureFlags";
import {usePrices} from "../../hooks/usePrices";
import {fetchCachedSlotAround} from "../../utils/fetchCachedSlotAround";
import {fetchPricingData} from "../../utils/fetchPricingData";
import {filterLocationsBySpecialtyId} from "../../utils/filterLocationsBySpecialtyId";
import {getPriceByApptReasonId} from "../../utils/getPriceByApptReasonId";
import {getSchedulingRedirectUrl} from "../../utils/getRedirectUrl";
import {locationsByRegion} from "../../utils/locationsByRegion";
import {v5Pages} from "../_common/_constants";
import {getSendClickedCareDiscoveryRowEvent} from "./Reason/CareDiscoverySearch/careDiscoveryAnalytics";

export type CovidModalItem = {
  icon?: string;
  title: string;
  subTitle: string;
  howLong?: string;
  price?: DefaultTFuncReturn | number;
  slot?: ApptSlot | null;
  slug: string;
  reasonId?: string;
  specialtyId?: string;
  onClick?: () => void;
  href?: string;
  travel?: boolean;
  flowId?: string;
  specialtyIdInCategory?: string;
};

const TRAVEL = "TRAVEL";
const NONTRAVEL = "NONTRAVEL";
const LOCATIONS_LIMIT = 5; // count of locations with specialty

const CovidTestModal = ({
  showTravelContent,
  covidLocationId,
  isModal = true,
  isCovidTestingPage,
}: {
  showTravelContent: boolean;
  covidLocationId?: string;
  isModal?: boolean;
  isCovidTestingPage?: boolean;
}): JSX.Element => {
  const i18n = useTranslation();
  const {locations, locationsSorted} = useTypedSelector(({config}) => config);
  const selectedRegion = useTypedSelector(selectSelectedRegion);
  const pricingData = useTypedSelector(state => state.regionPricing);
  const hasPricingData = pricingData.tier1.length !== 0;
  const locsByRegion = useMemo(
    () => locationsByRegion(locations, selectedRegion),
    [locations, selectedRegion],
  );

  useEffect(() => {
    if (!hasPricingData) fetchPricingData().then(data => dispatch(actions.setRegionPricing(data)));
  }, [hasPricingData]);

  const dispatch = useDispatch();
  const enableCache = useFeatureFlag<boolean>(FeatureFlag.GROWTH_CACHED_SLOTS_ENABLED);

  // if travel content opened from inside modal or not
  const [isTravelRefererModal, setIsTravelRefererModal] = useState(false);
  const [slotsFetched, setSlotsFetched] = useState(false);
  const {asPath, pathname} = useRouter();

  const preselectedLocations = useMemo(
    () => locations.filter(l => l.id === covidLocationId),
    [covidLocationId, locations],
  );
  const location = preselectedLocations[0];

  const titles = {[NONTRAVEL]: i18n.t("COVID Testing"), [TRAVEL]: i18n.t("COVID Travel Testing")};

  const reasonExtensions = useResultExtensions();

  const items: CovidModalItem[] = useMemo(
    () => [
      {
        icon: reasonExtensions[ApptReasonId.COVID_SICK_VISIT].icon,
        title: i18n.t("COVID Sick Visit"),
        subTitle: i18n.t(
          "Full provider evaluation for patients with symptoms, including any necessary testing.",
        ),
        price: i18n.t("Covered by insurance"),
        howLong: reasonExtensions[ApptReasonId.COVID_SICK_VISIT].turnaround,
        reasonId: ApptReasonId.COVID_SICK_VISIT,
        specialtyId: SpecialtyId.COVID_TESTING_MOBILE,
        slug: "covid-sick-visit",
      },
      {
        icon: reasonExtensions[ApptReasonId.COVID_TESTING_NO_SYMPTOMS].icon,
        title: i18n.t("COVID Exposure Screening"),
        subTitle: i18n.t(
          "Full provider evaluation for patients with an exposure risk, including any necessary testing.",
        ),
        price: i18n.t("Covered by insurance"),
        howLong: reasonExtensions[ApptReasonId.COVID_TESTING_NO_SYMPTOMS].turnaround,
        reasonId: ApptReasonId.COVID_TESTING_NO_SYMPTOMS,
        specialtyId: SpecialtyId.COVID_TESTING_MOBILE,
        slug: "covid-exposure-screening",
      },
      {
        icon: "rapid-hepatitis-c-screening",
        title: i18n.t("Same-Day NAAT"),
        subTitle: i18n.t("No provider evaluation. Samples processed on-site."),
        howLong: reasonExtensions[ApptReasonId.COVID_TRAVEL_CLEARANCE_TESTING].turnaround,
        reasonId: ApptReasonId.COVID_TRAVEL_CLEARANCE_TESTING,
        specialtyId: SpecialtyId.COVID_TRAVEL_CLEARANCE,
        slug: "same-day-naat",
        specialtyIdInCategory: SpecialtyId.COVID_TESTING_MOBILE, // We want to show this item under Non-Travel list but it actually belongs to Travel items. Programatically, we need a way to know which category it belongs to filter it out.
      },
      {
        icon: "flight",
        title: i18n.t("COVID Travel Testing"),
        subTitle: i18n.t("No provider evaluation. RT-PCR and NAAT options available."),
        howLong: reasonExtensions[ApptReasonId.COVID_TRAVEL_CLEARANCE_TESTING].turnaround,
        reasonId: ApptReasonId.COVID_TRAVEL_CLEARANCE_TESTING,
        specialtyId: SpecialtyId.COVID_TRAVEL_CLEARANCE,
        slug: "covid-19-travel-clearance",
        onClick: () => {
          setIsTravelRefererModal(true);
          dispatch(
            actions.setConfig({
              covidModal: {showTravelContent: true, showCovidModal: true, covidLocationId},
            }),
          );
        },
      },
      {
        travel: true,
        icon: "rapid-hepatitis-c-screening",
        title: i18n.t("Same-Day NAAT"),
        subTitle: i18n.t("No provider evaluation. Samples processed on-site."),
        howLong: reasonExtensions[ApptReasonId.COVID_TRAVEL_CLEARANCE_TESTING].turnaround,
        reasonId: ApptReasonId.COVID_TRAVEL_CLEARANCE_TESTING,
        specialtyId: SpecialtyId.COVID_TRAVEL_CLEARANCE,
        slug: "same-day-naat",
      },
      {
        travel: true,
        icon: reasonExtensions[ApptReasonId.NEXT_DAY_RT_PCR].icon,
        title: i18n.t("Next-Day RT-PCR"),
        subTitle: i18n.t(
          "No provider evaluation. Samples processed in a lab with expedited results.",
        ),
        howLong: reasonExtensions[ApptReasonId.NEXT_DAY_RT_PCR].turnaround,
        reasonId: ApptReasonId.NEXT_DAY_RT_PCR,
        specialtyId: SpecialtyId.COVID_TRAVEL_CLEARANCE,
        slug: "next-day-rt-pcr",
      },
      {
        travel: true,
        icon: "rapid-hepatitis-c-screening",
        title: i18n.t("Same-Day Antigen"),
        subTitle: i18n.t("No provider evaluation. Samples processed on-site."),
        howLong: reasonExtensions[ApptReasonId.SAME_DAY_ANTIGEN].turnaround,
        reasonId: ApptReasonId.SAME_DAY_ANTIGEN,
        specialtyId: SpecialtyId.COVID_TRAVEL_CLEARANCE,
        slug: "same-day-antigen",
        price: reasonExtensions[ApptReasonId.SAME_DAY_ANTIGEN].price,
      },
      {
        travel: true,
        icon: "rapid-hepatitis-c-screening",
        title: i18n.t("Same-Day RT-PCR"),
        subTitle: i18n.t("No provider evaluation. Samples processed on-site."),
        howLong: reasonExtensions[ApptReasonId.SAME_DAY_RT_PCR].turnaround,
        reasonId: ApptReasonId.SAME_DAY_RT_PCR,
        specialtyId: SpecialtyId.COVID_TRAVEL_CLEARANCE,
        slug: "same-day-rt-pcr",
        price: reasonExtensions[ApptReasonId.SAME_DAY_RT_PCR].price,
      },
    ],
    [covidLocationId, dispatch, i18n, reasonExtensions],
  );

  const prices = usePrices(location?.region?.slug);

  const [itemsFinal, setItemsFinal] = useState<CovidModalItem[]>(items);

  useEffect(() => {
    (async () => {
      // @ts-expect-error TS2531: Object is possibly 'null'.
      if (isModal) document.getElementById("body").classList.add("preventScroll");

      if (!locationsSorted) return;

      items
        .map(item => {
          const {reasonId} = item;
          const flowId = v4();

          const price = item.price || getPriceByApptReasonId(reasonId || "", prices);

          const href = location
            ? getSchedulingRedirectUrl({
                practiceId: location.practiceId,
                locationId: location.id,
                specialtyId: item.specialtyId,
                apptReasonId: item.reasonId,
                flowId,
              })
            : `${v5Pages.getCareRoot}/${item.slug}`;

          return Promise.resolve({...item, price, href, flowId});
        })
        .sequence()
        .then(res => {
          setItemsFinal(res);
          return res;
        })
        .then(updatedItems => {
          // @ts-expect-error TS2684: The 'this' context of type '(Promise<{ price: string | number | object | null; href: string; icon: string; title: string; subTitle: string; howLong?: string | undefined; slot?: Partial<ApptSlot> | undefined; ... 4 more ...; specialtyIdInCategory?: string | undefined; }> | Promise<...>)[]' is not assignable to method's 'this' of type 'Promise<{ price: string | number | object | null; href: string; icon: string; title: string; subTitle: string; howLong?: string | undefined; slot?: Partial<ApptSlot> | undefined; ... 4 more ...; specialtyIdInCategory?: string | undefined; }>[]'.
          updatedItems
            .map(item => {
              if (enableCache) {
                const {reasonId, specialtyId} = item;
                const closestLocationsBySpecialty = filterLocationsBySpecialtyId(
                  location ? preselectedLocations : locsByRegion,
                  // @ts-expect-error TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
                  specialtyId,
                  LOCATIONS_LIMIT,
                );
                return fetchCachedSlotAround({
                  locations: closestLocationsBySpecialty,
                  specialtyId,
                  reasonId,
                  locationCount: LOCATIONS_LIMIT,
                }).then(slot => ({
                  ...item,
                  slot,
                }));
              }
              return Promise.resolve(item);
            })
            .sequence()
            .then(slots => {
              setItemsFinal(slots);
              setSlotsFetched(true);
            });
        });
    })();
  }, [
    enableCache, // watch `enableCache` to re-fetch slots when it changes because it's a feature flag
    isModal,
    items,
    location,
    locationsSorted,
    locsByRegion,
    preselectedLocations,
    prices,
  ]);

  useEffect(
    () =>
      function cleanup() {
        // @ts-expect-error TS2531: Object is possibly 'null'.
        document.getElementById("body").classList.remove("preventScroll");
      },
    [],
  );
  const itemsGrouped = itemsFinal.groupBy(({travel}) => (travel ? TRAVEL : NONTRAVEL));

  const onClose = useCallback(() => {
    dispatch(actions.setConfig({covidModal: {showCovidModal: false}}));
  }, [dispatch]);

  const renderModal = () => (
    <div
      className={`${
        !isModal
          ? ""
          : "pos-f w100vw minh100vh left0 top0 zIndex9999 df aic jcc ovf-y-a contrast-tb bg-white-f-md"
      }`}
      style={{backgroundColor: Color(colors.gray800).alpha(0.5), backdropFilter: "blur(10px)"}}
      role="dialog"
      tabIndex={-1}
      aria-label={i18n.t("Which COVID test do you need")}
      aria-modal
    >
      <OutsideClickHandler
        onOutsideClick={() => dispatch(actions.setConfig({covidModal: {showCovidModal: false}}))}
      >
        <div className="bg-white w100p maxw46 br2 pos-r h100vh-md w100vw-md br0-md">
          <div className="df jcsb p6 ph0-md">
            <button
              tabIndex={0}
              className={`cIcon-arr2Left fs20 brdn bg-transparent cp hover-gray400 ${
                !showTravelContent || !isTravelRefererModal ? "op0" : ""
              }`}
              aria-label={i18n.t("Back")}
              onClick={() =>
                dispatch(
                  actions.setConfig({covidModal: {showTravelContent: false, showCovidModal: true}}),
                )
              }
            />
            <button
              className="fs20 brdn bg-transparent cp hover-gray400"
              onClick={() => dispatch(actions.setConfig({covidModal: {showCovidModal: false}}))}
            >
              <span className="visually-hidden">{i18n.t("Close Dialog")}</span>
              <span className="cIcon-close1" aria-hidden />
            </button>
          </div>
          <div className="ph15 pb10 ph4-md pb40-md">
            {showTravelContent ? (
              <>
                <h2 className="fs24-md">{i18n.t("Which travel test do you need?")}</h2>
                <p className="font-i fs14-md">
                  {i18n.t(
                    "Patients are responsible for verifying which test meets the travel requirements for their destination. Travel testing is recommended for asymptomatic patients, and is not covered by insurance.",
                  )}
                </p>
              </>
            ) : (
              <h3 className="fs24-md">{i18n.t("Which COVID test do you need?")}</h3>
            )}

            <ul className="mt4">
              {itemsGrouped[showTravelContent ? TRAVEL : NONTRAVEL]
                .filter(({slot}) => !location || slot)
                .map(({href, onClick, flowId, ...item}, index) => {
                  const isClinicOrHome =
                    pathname === v5Pages.home || pathname === v5Pages.clinicDetails;

                  const _onClick = isClinicOrHome
                    ? () => {
                        if (onClick) {
                          // This check is here because the previously defined onclick opens modal
                          onClick();
                        } else {
                          // If you don't open the modal, close it
                          dispatch(actions.setConfig({covidModal: {showCovidModal: false}}));
                        }
                        // Always report click event in modal, but only pass flowId if patient app link
                        getSendClickedCareDiscoveryRowEvent("base")(
                          {
                            name: item.title,
                            appointment_reason: item.reasonId,
                            slug: item.slug,
                          },
                          item.slot || undefined,
                          index + 1,
                          href && /patients/gi.test(href) ? flowId : undefined,
                        );
                      }
                    : undefined;

                  return (
                    <CovidTestModalItem
                      {...item}
                      key={item.title}
                      index={index}
                      onClick={_onClick}
                      // If `onClick` is present, it means it should not link to anywhere
                      href={onClick ? undefined : href}
                      onClose={onClose}
                      asPath={asPath}
                      isModal={isModal}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );

  const renderNonModal = () => {
    if (!slotsFetched) return <span>{i18n.t("Loading...")}</span>;
    return (
      <div>
        {itemsGrouped.getKeys().map(g => {
          const items = itemsGrouped[g]
            .filter(({slot, onClick}) => (!location || slot) && !onClick)
            .filter(
              ({specialtyIdInCategory}) =>
                !isCovidTestingPage || (isCovidTestingPage && !specialtyIdInCategory),
            );
          if (!items.length) return null;

          // check if location has items with specialtyIds (only in location page)
          if (location) {
            const isSpecialtyIdsInLocation = items.every(it => {
              const specialtyId = it.specialtyIdInCategory || it.specialtyId;
              // @ts-expect-error TS2345: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
              return location.specialtyIds.includes(specialtyId);
            });
            if (!isSpecialtyIdsInLocation) return null;
          }

          return (
            // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ NONTRAVEL: string; TRAVEL: string; }'.
            <div key={titles[g]} className={!isCovidTestingPage ? `mt8` : ""}>
              {/* @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ NONTRAVEL: string; TRAVEL: string; }'. */}
              {!isCovidTestingPage && <h2 className="fs24">{titles[g]}</h2>}
              <ul>
                {items
                  .map((item, index) => (
                    <CovidTestModalItem
                      {...item}
                      key={item.title}
                      index={index}
                      onClose={onClose}
                      asPath={asPath}
                      isModal={isModal}
                      isCovidTestingPage={isCovidTestingPage}
                    />
                  ))
                  .compact()}
              </ul>
            </div>
          );
        })}
      </div>
    );
  };

  return !isModal ? renderNonModal() : renderModal();
};

export default CovidTestModal;
