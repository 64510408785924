import {regionsByPricingTiers} from "../constants/regionsByPricingTier";
import {PricingTier} from "../store/slices/regionPricing";
import {RegionSlug} from "../store/types";

const DEFAULT_TIER = "tier2";

export const getPricingTierByRegionSlug = (slug: RegionSlug): PricingTier => {
  const result = Object.entries(regionsByPricingTiers).find(([, value]) => value.includes(slug));

  if (!result) return DEFAULT_TIER;

  const [tier] = result;

  return tier as PricingTier;
};
