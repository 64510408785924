import {useState} from "react";
import {useDispatch} from "react-redux";
import {actions, useTypedSelector} from "src/store";
import {PricingTierItem} from "src/store/slices/regionPricing";
import {selectSelectedRegion} from "src/store/slices/userLocation";
import {RegionSlug} from "src/store/types";

import {useEffectAsync} from "../components/_common/Carbon";
import {fetchPricingData} from "../utils/fetchPricingData";
import {getPricingTierByRegionSlug} from "../utils/getPricingTierBySlug";

export const usePrices = (regionSlug?: string): PricingTierItem[] => {
  const [prices, setPrices] = useState<PricingTierItem[]>([]);
  const dispatch = useDispatch();
  const regionPricing = useTypedSelector(state => state.regionPricing);
  const selectedRegion = useTypedSelector(selectSelectedRegion);
  const slugToCheck = regionSlug || selectedRegion;

  useEffectAsync(async () => {
    const priceTier = getPricingTierByRegionSlug(slugToCheck as RegionSlug);
    const maybePrices = regionPricing[priceTier];

    if (!maybePrices?.length) {
      const newRegionPricing = await fetchPricingData();
      const newPrices = newRegionPricing[priceTier];
      dispatch(actions.setRegionPricing(newRegionPricing));
      setPrices(newPrices);
    } else {
      setPrices(maybePrices);
    }
  }, [selectedRegion, regionSlug, regionPricing]);

  return prices;
};
