import TreeOverlay from "@components/components/Tree/TreeOverlay";
import {ButtonVariant} from "@components/components/Tree/types";
import TextInput from "@components/Input/TextInput";
import dynamic from "next/dynamic";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {TreeItem, TreeItemIndex} from "react-complex-tree";
import {useCurrentRoute} from "src/hooks/useCurrentRoute";

// Need to do this to ensure there's a document
const DynamicComplexTreeWrapper = dynamic(
  () => import("@components/components/Tree/ComplexTreeWrapper"),
  {ssr: false},
);

type SelectedItem = {
  name: string;
  id: string | number;
};

interface TreeViewProps {
  initialSelected?: SelectedItem;
  initialExpandedItems: string[];
  selectorName: string;
  searchLabel: string;
  searchPlaceholder: string;
  buildTree: (searchTerm: string) => Record<TreeItemIndex, TreeItem<string>>;
  // @ts-expect-error TS7010: 'handleSelect', which lacks return-type annotation, implicitly has an 'any' return type.
  handleSelect(id: string | number);
  handleSearch?: (
    searchTerm?: string,
    treeData?: Record<TreeItemIndex, TreeItem<string>>,
    currentRoute?: string,
  ) => void;
  buttonVariant: ButtonVariant;
}

export const TreeView: React.FC<TreeViewProps> = ({
  buildTree,
  initialExpandedItems,
  initialSelected,
  selectorName,
  searchLabel,
  searchPlaceholder,
  handleSelect,
  handleSearch,
  buttonVariant,
}) => {
  // @ts-expect-error TS2339, TS2339: Property 'name' does not exist on type 'SelectedItem | undefined'.,  Property 'id' does not exist on type 'SelectedItem | undefined'.
  const {name, id} = initialSelected;
  const [selectedId, setSelectedId] = useState(id);
  const [searchTerm, setSearch] = useState("");

  const currentRoute = useCurrentRoute();

  const tree = useMemo(() => buildTree(searchTerm), [buildTree, searchTerm]);
  const selectedItem = useMemo(() => tree[selectedId], [selectedId, tree]);
  const handleClose = useCallback(() => setSearch(""), []);

  const handleTreeItemSelect = (close: () => void) => (item: TreeItem<string>) => {
    setSelectedId(item.index);
    handleSelect(item.index);
    close();
  };

  useEffect(() => {
    if (handleSearch) handleSearch(searchTerm, tree, currentRoute);
  }, [currentRoute, handleSearch, searchTerm, tree]);

  useEffect(() => {
    setSelectedId(id);
  }, [id]);

  return (
    <TreeOverlay
      selectedItem={selectedItem?.data ?? name}
      selectorName={selectorName}
      onClose={handleClose}
      variant={buttonVariant}
      renderChildren={close => (
        <div className="bg-white pv7 br5 bs2 ph6 contrast-tb">
          <TextInput
            onChange={setSearch}
            className="mb-2"
            icon="cIcon-search -ml-2 mr1 fs28-f"
            placeholder={searchPlaceholder}
            ariaLabel={searchLabel}
            autoFocus
            autoComplete="off"
          />
          <DynamicComplexTreeWrapper
            onSelect={handleTreeItemSelect(close)}
            tree={tree}
            defaultExpandedItems={initialExpandedItems}
            defaultSelectedItem={selectedItem?.index ?? id}
          />
        </div>
      )}
    />
  );
};

export default React.memo(TreeView);
