import {TreeItem, TreeItemIndex} from "react-complex-tree";
import {analytics} from "src/utils/analytics";

import {flattenSearchResultsForAnalytics} from "./flattenSearchResultsForAnalytics";

export const sendAnalyticsDataOnRegionChange = (region: string, currentRoute: string): void => {
  analytics.post({
    category: analytics.category.LOCATION_DISCOVERY,
    label: analytics.label.REGION_DROP_DOWN,
    action: analytics.action.CLICKED,
    extraData: {
      isMapPopup: false,
      value: region,
      source: currentRoute === "index" ? "footer" : currentRoute,
    },
  });
};

// @ts-expect-error TS7034: Variable 'interval' implicitly has type 'any' in some locations where its type cannot be determined.
let interval;
export const sendAnalyticsDataOnSearch = (
  searchTerm: string,
  tree: Record<TreeItemIndex, TreeItem<string>>,
  currentRoute: string,
): void => {
  // @ts-expect-error TS7005, TS7005: Variable 'interval' implicitly has an 'any' type.,  Variable 'interval' implicitly has an 'any' type.
  if (interval) clearInterval(interval);

  interval = setTimeout(() => {
    const hasSearchTerm = searchTerm.length;
    if (hasSearchTerm) {
      const searchResoults = flattenSearchResultsForAnalytics(tree);

      analytics.post({
        category: analytics.category.LOCATION_DISCOVERY,
        label: analytics.label.REGION,
        action: analytics.action.SEARCHED,
        extraData: {
          options: searchResoults,
          searchText: searchTerm,
          resultsCount: searchResoults.length,
          source: currentRoute === "index" ? "footer" : currentRoute,
        },
      });
    }
  }, 500);
};
