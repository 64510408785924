import {getRegionSlug} from "../store/getRegionSlug";

export const regionsByPricingTiers = {
  tier1: [getRegionSlug("sf-bay-area")],
  tier2: [
    getRegionSlug("los-angeles"),
    getRegionSlug("colorado"),
    getRegionSlug("florida"),
    getRegionSlug("nevada"),
    getRegionSlug("texas"),
    getRegionSlug("arizona"),
    getRegionSlug("washington"),
    getRegionSlug("greater-san-diego"),
    getRegionSlug("orange-county"),
    getRegionSlug("san-luis-obispo"),
    getRegionSlug("inland-empire"),
    getRegionSlug("sacramento"),
    getRegionSlug("ohio"),
    getRegionSlug("pennsylvania"),
    getRegionSlug("massachusetts"),
  ],
  tier3: [getRegionSlug("new-jersey")],
};
