import {PriceSlugByReasonId} from "../constants/priceSlugs";
import {PricingTierItem} from "../store/slices/regionPricing";

export const getPriceByApptReasonId = (
  apptReasonId: keyof typeof PriceSlugByReasonId | string,
  prices: PricingTierItem[],
): string | null => {
  // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ "23b5bd54-42e3-4e5a-a05b-b21c4be55601": string; "b475dca5-c9cf-41b5-abed-9b69982088ad": string; }'.
  const maybePriceSlug = apptReasonId in PriceSlugByReasonId && PriceSlugByReasonId[apptReasonId];
  const maybePrice = maybePriceSlug
    ? prices.find(price => price.slug === maybePriceSlug)?.price
    : null;

  return typeof maybePrice === "number" ? `$${maybePrice / 100}` : null;
};
