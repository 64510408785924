import TreeView from "@components/components/Tree";
import {ButtonVariant} from "@components/components/Tree/types";
import {useTranslation} from "ni18n";
import React, {useCallback, useMemo} from "react";
import {TreeItem, TreeItemIndex} from "react-complex-tree";
import {useCurrentRoute} from "src/hooks/useCurrentRoute";

import {actions, useTypedDispatch, useTypedSelector} from "../../../store";
import {getRegionSlug} from "../../../store/getRegionSlug";
import {selectSelectedRegion} from "../../../store/slices/userLocation";
import {RegionSlug} from "../../../store/types";
import {generateRegionData} from "../../../utils/regionUtils";
import {getStateCodeFromRegionSlug} from "../../../utils/stateUtils";
import {
  sendAnalyticsDataOnRegionChange,
  sendAnalyticsDataOnSearch,
} from "./utils/handleSendAnalyticsData";

interface Region {
  name: string;
  id: string;
}

const sfBayAreaRegion = {
  slug: getRegionSlug("sf-bay-area"),
  name: "Bay Area",
};

const isCARegion = (slug: string) => getStateCodeFromRegionSlug(slug) === "CA";
const californiaParentItem = {id: "ca", name: "California"};

const buildRegionTree =
  (regions: Region[]) =>
  (searchTerm: string): Record<TreeItemIndex, TreeItem<string>> => {
    const filteredRegions = regions.filter(({name}) =>
      name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    const filteredCARegions = filteredRegions
      .filter(({id}) => isCARegion(id))
      .sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
      .map(({id}) => id);
    const otherRegions = filteredRegions.filter(({id}) => !isCARegion(id));
    const rootChildren = filteredCARegions.length
      ? otherRegions.append(californiaParentItem)
      : otherRegions;

    const root = {
      index: "root",
      isFolder: true,
      children: rootChildren.sortBy(({name}) => name).map(({id}) => id),
      data: "Root",
    };

    return filteredRegions.append(californiaParentItem).reduce(
      (prev, curr) => {
        if (curr.name === californiaParentItem.name && filteredCARegions.length) {
          return {
            ...prev,
            [curr.id]: {
              index: curr.id,
              isFolder: true,
              children: filteredCARegions,
              data: curr.name,
            },
          };
        }

        return {
          ...prev,
          [curr.id]: {
            index: curr.id,
            isFolder: false,
            children: null,
            data: curr.name,
          },
        };
      },
      {root},
    );
  };

interface RegionTreeSelector {
  buttonVariant?: ButtonVariant;
  initialRegionSlug?: RegionSlug;
  onSelect?: (slug: RegionSlug) => void;
  hideVirtualOnly?: boolean;
  inClinicSpecialtyIds?: string[];
}

const RegionTreeSelector: React.FC<RegionTreeSelector> = ({
  buttonVariant = "primary",
  onSelect,
  initialRegionSlug,
  inClinicSpecialtyIds,
  hideVirtualOnly = false,
}) => {
  const i18n = useTranslation();
  const dispatch = useTypedDispatch();
  const selectedFromStore = useTypedSelector(selectSelectedRegion) ?? "sf-bay-area";
  const {locations} = useTypedSelector(({config}) => config);
  const closestRegion = locations[0]?.region || sfBayAreaRegion;

  const selectedRegion = initialRegionSlug ?? selectedFromStore;
  const currentRoute = useCurrentRoute();

  const selectorName = i18n.t("Region");
  const searchLabel = i18n.t("Search by region name");
  const searchPlaceholder = i18n.t("Search regions");

  const regions = useMemo(
    () => generateRegionData(locations, inClinicSpecialtyIds, hideVirtualOnly),
    [hideVirtualOnly, inClinicSpecialtyIds, locations],
  );
  const expandedItems = useMemo(
    () => (getStateCodeFromRegionSlug(selectedFromStore) === "CA" ? [californiaParentItem.id] : []),
    [selectedFromStore],
  );
  const items = useMemo(() => regions.map(({slug, name}) => ({name, id: slug})), [regions]);
  const initialSelected = useMemo(
    () =>
      items.find(({id}) => id === selectedRegion) || {
        id: closestRegion.slug,
        name: closestRegion.name,
      },
    [items, selectedRegion],
  );

  const handleSelect = useCallback(
    (slug: RegionSlug) => {
      if (selectedFromStore !== slug) dispatch(actions.setUserLocation({selectedRegion: slug}));
      if (onSelect) onSelect(slug);
      sendAnalyticsDataOnRegionChange(slug, currentRoute);
    },
    [currentRoute, dispatch, onSelect, selectedFromStore],
  );

  const buildTree = useMemo(() => buildRegionTree(items), [items]);

  return (
    <TreeView
      buttonVariant={buttonVariant}
      selectorName={selectorName}
      searchLabel={searchLabel}
      searchPlaceholder={searchPlaceholder}
      initialSelected={initialSelected}
      initialExpandedItems={expandedItems}
      handleSelect={handleSelect}
      // @ts-expect-error TS2322: Type '(searchTerm: string, tree: Record<TreeItemIndex, TreeItem<string>>, currentRoute: string) => void' is not assignable to type '(searchTerm?: string | undefined, treeData?: Record<TreeItemIndex, TreeItem<string>> | undefined, currentRoute?: string | undefined) => void'.
      handleSearch={sendAnalyticsDataOnSearch}
      buildTree={buildTree}
    />
  );
};

export default React.memo(RegionTreeSelector);
