import {cloneDeep, isEmpty} from "lodash";
import {TreeItem, TreeItemIndex} from "react-complex-tree";

export function flattenSearchResultsForAnalytics(
  regions: Record<TreeItemIndex, TreeItem<string>>,
): (string | number)[] {
  if (isEmpty(regions)) return [];

  // Deep Clone tree object to avoid unexpected reference issues
  const regionsData = cloneDeep(regions);

  const statesWithMultipleRegion = Object.values(regionsData).reduce(
    // @ts-expect-error TS2769: No overload matches this call.
    (acc, next) => (next.index !== "root" && next.isFolder ? [...acc, next.index] : acc),
    [],
  );

  // @ts-expect-error TS2322: Type 'TreeItemIndex[] | undefined' is not assignable to type '(string | number)[]'.
  if (isEmpty(statesWithMultipleRegion)) return regionsData?.root?.children;

  const nestedStateRegions = Object.values(regionsData).reduce(
    // @ts-expect-error TS2769, TS2488: No overload matches this call.,  Type 'TreeItemIndex[] | undefined' must have a '[Symbol.iterator]()' method that returns an iterator.
    (acc, next) => (next.index !== "root" && next.isFolder ? [...acc, ...next.children] : acc),
    [],
  );

  // @ts-expect-error TS2488: Type 'TreeItem<string>' must have a '[Symbol.iterator]()' method that returns an iterator.
  const regionList = [...regionsData.root.children, ...nestedStateRegions].filter(
    // @ts-expect-error TS2339: Property 'includes' does not exist on type 'TreeItem<string>'.
    region => !statesWithMultipleRegion.includes(region),
  );

  return regionList;
}
