import {CovidModalItem} from "@components/CovidTestModal";
import Link from "next/link";
import {useTranslation} from "ni18n";
import React from "react";

import {useReadableSlotTime} from "../../hooks/useReadableSlotTime";
import {CardRow, CardRowGroup} from "./Composition/CardRow";
import {Dot} from "./Composition/Dot";
import Tag from "./Composition/Tag";

type Props = CovidModalItem & {
  onClose?: () => void;
  index: number;
  asPath: string;
  href?: string;
  isModal?: boolean;
  isCovidTestingPage?: boolean;
};

const CovidTestModalItem = ({
  title,
  onClick,
  slot,
  href,
  icon,
  subTitle,
  howLong,
  price,
  index,
  onClose,
  asPath,
  isModal,
  isCovidTestingPage,
}: Props): JSX.Element => {
  const i18n = useTranslation();
  const readableSlotTime = useReadableSlotTime(slot?.time);
  // Using `asPath` as `href` is a hack so that we don't need to render a button instead of anchor, bad a11y practice
  const finalHref = href || asPath;
  const mobileView = !isCovidTestingPage && !isModal;

  return (
    <li>
      <Link
        href={finalHref}
        className={`df pv4 pos-r showOnHoverWrapper ${
          index && !mobileView ? "brdt brd-gray100" : ""
        }`}
        onClick={onClick || onClose}
        data-cy="home-widget-link-inner"
      >
        <div className={`w100p pt2 maxw3-md ${mobileView ? "maxw3" : "maxw4"}`}>
          <span
            className={`cIcon-${icon || "stethoscope"} darkBlue fs32-md ${
              mobileView ? "fs32" : "fs42"
            }`}
            aria-hidden
          />
        </div>
        <div className="fx pr4">
          <h3 className={`font-csb gray800 fs18-md ${mobileView ? "fs18" : "fs22"}`}>{title}</h3>
          <span className="font-i gray800 lh12 fs14-md">{subTitle}</span>

          <CardRow className="mt1">
            {howLong && <Tag>{howLong}</Tag>}
            <CardRowGroup>
              {price && <strong className="font-isb wsnw">{price}</strong>}
              {readableSlotTime && (
                <>
                  {price && <Dot />}
                  <em className={`font-isb fsn wsnw ${mobileView ? "" : "darkGreen"}`}>
                    {readableSlotTime}
                  </em>
                </>
              )}
            </CardRowGroup>
            {mobileView && (
              <>
                <Dot />
                <span className="darkGreen font-isb">{i18n.t("Book")}</span>
              </>
            )}
          </CardRow>
        </div>
        {!mobileView && (
          <>
            <span className="pos-a font-ib fs12 white bg-gray800 br5 pv3 ph4 brdn right0 cp hover-bg-gray600 op0 showOnHover dn-md">
              {i18n.t("Select")}
            </span>
            <div className="pt2">
              <span className="cIcon-arrRight fs26" aria-hidden />
            </div>
          </>
        )}
      </Link>
    </li>
  );
};

export default CovidTestModalItem;
