import "isomorphic-fetch";

import memoizee from "memoizee";

import {dev, pricingWorkerUrls} from "../components/_common/_constants";
import {RegionPricing} from "../store/slices/regionPricing";

export const fetchPricingData: () => Promise<RegionPricing> = memoizee(() =>
  fetch(dev ? pricingWorkerUrls.dev : pricingWorkerUrls.prod)
    .then(res => res.json())
    .catch(() => {
      const tier1 = import("@data/localTier1Prices.json").then(module => module.default);
      const tier2 = import("@data/localTier2Prices.json").then(module => module.default);
      const tier3 = import("@data/localTier3Prices.json").then(module => module.default);

      return Promise.all([tier1, tier2, tier3]).then(res => {
        const [tier1, tier2, tier3] = res;
        return {
          tier1,
          tier2,
          tier3,
        };
      });
    }),
);
