import assert from "assert";

export const isDeepEqual = (val1: unknown, val2: unknown): boolean => {
  try {
    assert.deepStrictEqual(val1, val2);
    return true;
  } catch {
    return false;
  }
};
