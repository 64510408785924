import {useButton} from "@react-aria/button";
import {FocusScope} from "@react-aria/focus";
import {
  DismissButton,
  useOverlay,
  useOverlayPosition,
  useOverlayTrigger,
} from "@react-aria/overlays";
import {mergeProps} from "@react-aria/utils";
import {useOverlayTriggerState} from "@react-stately/overlays";
import {omit} from "lodash";
import React, {forwardRef} from "react";

import {useTypedSelector} from "../../../../store";
import {ButtonVariant} from "./types";

const CONTAINER_WIDTH = 350;
const variantProps = {
  primary: {
    className: "brdn br5 fw6 p3 df aic bg-transparent cp aria-focus contrast-tb",
    style: {},
  },
  secondary: {
    className: "brdn br2 fw4 p4 df aic cp aria-focus bg-gray100 jcsb aibl contrast-tb",
    style: {width: CONTAINER_WIDTH},
  },
  locations: {
    className:
      "br2 flex grow w-full items-center cursor-pointer justify-between aibl fs14 bg-gray50 gray600 hover-gray800 ml0 p4 p-3 sm:p-4 brd1nc brd-gray100 minw10 contrast-tb",
    style: {maxWidth: CONTAINER_WIDTH},
  },
};

interface PopoverProps {
  isOpen: boolean;
  // @ts-expect-error TS7010: 'onClose', which lacks return-type annotation, implicitly has an 'any' return type.
  onClose();
}

const Popover = forwardRef<HTMLDivElement, PopoverProps & React.HTMLProps<HTMLDivElement>>(
  ({children, isOpen, onClose, ...otherProps}, ref) => {
    const {overlayProps} = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      ref as React.RefObject<HTMLElement>,
    );

    return (
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...mergeProps(overlayProps, otherProps)}
          ref={ref}
          className="mt2 pos-a zIndex1"
          style={{width: CONTAINER_WIDTH}}
        >
          {children}
          <DismissButton onDismiss={onClose} />
        </div>
      </FocusScope>
    );
  },
);

const MemoPopover = React.memo(Popover);
Popover.displayName = "Popover";

interface TreeOverlayProps {
  selectedItem: string;
  selectorName: string;
  renderChildren: (close: () => void) => React.ReactElement;
  // @ts-expect-error TS7010: 'onClose', which lacks return-type annotation, implicitly has an 'any' return type.
  onClose();
  variant: ButtonVariant;
}

export const TreeOverlay: React.FC<TreeOverlayProps> = ({
  onClose,
  selectorName,
  renderChildren,
  selectedItem,
  variant,
}) => {
  const state = useOverlayTriggerState({});
  const triggerRef = React.useRef<HTMLButtonElement>();
  const overlayRef = React.useRef<HTMLDivElement>();
  const {isMobile} = useTypedSelector(state => state.config);

  // @ts-expect-error TS2345: Argument of type 'MutableRefObject<HTMLButtonElement | undefined>' is not assignable to parameter of type 'RefObject<HTMLElement>'.
  const {triggerProps, overlayProps} = useOverlayTrigger({type: "tree"}, state, triggerRef);
  const {overlayProps: positionProps} = useOverlayPosition({
    // @ts-expect-error TS2322: Type 'MutableRefObject<HTMLButtonElement | undefined>' is not assignable to type 'RefObject<HTMLElement>'.
    targetRef: triggerRef,
    // @ts-expect-error TS2322: Type 'MutableRefObject<HTMLDivElement | undefined>' is not assignable to type 'RefObject<HTMLElement>'.
    overlayRef,
    placement: "top",
    offset: 5,
    isOpen: state.isOpen,
    ...(isMobile && {onClose: () => null}),
  });
  const {buttonProps} = useButton(
    {
      onPress: () => state.open(),
    },
    // @ts-expect-error TS2769: No overload matches this call.
    triggerRef,
  );

  const handleClose = () => {
    onClose();
    state.close();
  };

  return (
    <div className="pos-r dib zIndex2 grow" style={{maxWidth: 350}}>
      <div id="react-aria-select-descriptor" className="visually-hidden">
        {selectorName}
      </div>
      <button
        {...buttonProps}
        {...omit(triggerProps, "onPress")}
        aria-labelledby="react-aria-select-descriptor react-aria-selected-item"
        // @ts-expect-error TS2322: Type 'MutableRefObject<HTMLButtonElement | undefined>' is not assignable to type 'LegacyRef<HTMLButtonElement> | undefined'.
        ref={triggerRef}
        // @ts-expect-error TS2783: 'className' is specified more than once, so this usage will be overwritten.
        className="brdn br5 fw6 p3 df aibl bg-transparent cp aria-focus"
        {...variantProps[variant]}
        data-cy="comboboxButton"
      >
        <span id="react-aria-selected-item" className="font-c font-i-sm fs18 fs14-sm lh26 mr2">
          {selectedItem}
        </span>
        <span className="cIcon-dropdown-arrow-down" aria-hidden="true" />
      </button>
      {state.isOpen && (
        <MemoPopover
          {...overlayProps}
          {...positionProps}
          // @ts-expect-error TS2322: Type 'MutableRefObject<HTMLDivElement | undefined>' is not assignable to type 'Ref<HTMLDivElement> | undefined'.
          ref={overlayRef}
          isOpen={state.isOpen}
          onClose={handleClose}
        >
          {renderChildren(handleClose)}
        </MemoPopover>
      )}
    </div>
  );
};

export default React.memo(TreeOverlay);
